import i18n from '@/translations'

export default [
  {
    color: 'green',
    textColor: 'white',
    label: i18n.t('yes'),
    value: 'YES',
  },
  {
    color: 'red',
    textColor: 'white',
    label: i18n.t('no'),
    value: 'NO',
  },
  {
    color: 'blue',
    textColor: 'white',
    label: i18n.t('paid'),
    value: 'PAID',
  },
]
