<template>
  <chip-component :payload="getStatus" />
</template>

<script>
import ChipComponent from '@/components/ChipComponent.vue'
import ScheduledPaymentChipStatusEnum from './ScheduledPaymentStatusEnum'

export default {
  name: 'ScheduledPaymentChipStatusComponent',
  components: { ChipComponent },
  props: {
    value: {
      type: [String, Boolean],
      default: '',
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          // eslint-disable-next-line no-unused-vars
          transform: (value, row) => value,
        }
      },
    },
  },
  data: () => ({
    availableStatus: ScheduledPaymentChipStatusEnum,
  }),
  computed: {
    getStatus() {
      return this.availableStatus.find((el) => el.value == this.options.transform(this.value))
    },
  },
}
</script>
