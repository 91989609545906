<script>
import ListView from '@/components/ListView'
import { mapActions, mapGetters } from 'vuex'
import ScheduledPaymentChipStatusComponent from './ScheduledPaymentChipStatusComponent'
import Moment from 'moment'

export default {
  extends: ListView,
  name: 'ViewListInvoices',
  data() {
    return {
      namePage: this.$i18n.t('transportationCodes'),
      path: 'transportationDocument',
      filterContent: () => import('./TransportationDocumentFiltersComponent.vue'),
      headerContent: () => import('./TransportationDocumentHeaderComponent.vue'),
      hideDefaultActions: true,
      addNew: false,
      enablePrinting: true,
      dataProperties: { path: 'transportationDocument', namePage: this.$i18n.t('transportationCodes') },
      headers: [
        {
          text: this.$i18n.t('transportationCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'code',
          width: '8%',
          customFilter: () => import('./components/DataTableTransportationDocCustomFilter.vue'),
        },
        {
          text: this.$i18n.t('issuerCompanyCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportCode',
        },
        {
          text: this.$i18n.t('documentNumber'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'documentNumber',
        },
        {
          text: this.$i18n.t('paymentForecast'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'issueDate',
          filterType: 'date',
          sortRule: (a, b) => {
            return Moment(a, 'DD/MM/YYYY') > Moment(b, 'DD/MM/YYYY') ? 1 : -1
          },
        },
        {
          text: this.$i18n.t('clearingDocument'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'clearingDocument',
        },
        {
          text: this.$i18n.t('accKey'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'accKey',
        },
        {
          text: this.$i18n.t('scheduledPayment'),
          align: 'start',
          sortable: true,
          value: 'isPaid',
          component: ScheduledPaymentChipStatusComponent,
          createFilter: true,
          options: {
            transform(value) {
              let paid = value == 'Não' ? 'NO' : 'PAID'
              return value == 'Sim' ? 'YES' : paid
            },
          },
        },
        {
          text: this.$i18n.t('paymentDate'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'paymentDate',
        },
        {
          text: this.$i18n.t('contNumber'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'contNumber',
        },
        {
          text: this.$i18n.t('sapCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'sapCode',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('transportationDocumentStore', {
      getGoodList: 'getList',
    }),
  },
  methods: {
    ...mapActions('transportationDocumentStore', ['setDataToList', 'setDefaultPath']),
    async getItemsList() {
      return true
    },
  },
}
</script>
